import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {IntlProvider} from './intl';

import 'bootstrap/dist/css/bootstrap.css';
import "./assets/vendor/icon-line-pro/style.css";
import "./assets/vendor/icon-awesome/css/font-awesome.min.css";
import "./assets/css/unify-components.css";
import "./assets/css/unify.css";
import "./assets/css/custom.css";

ReactDOM.render(
    <IntlProvider defaultLocale="browser" locales={['nl', 'fr', 'en']}>
        <App/>
    </IntlProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();
